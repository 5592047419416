<template>
<div class="grid grid-row-2 gap-5 mb-40">
    <div class="grid grid-cols-8 gap-4 my-10 tracking-widest">
        <div class=" col-start-3 col-span-5 mb-24 ">
        <div class=" my-5 font-kgout text-5xl md:text-7xl text-orange ">
        <p>BRANDING</p>
        </div>
        <div class="font-kg text-2xl  text-gray-400">
            Brending- to proces polegający na budowaniu świadomości marki. Spójność i konsekwencja w tym procesie zdecydowanie mają wpływ na jego rozpoznawalność.
        </div>
        </div>
    </div>
    <div class="md:grid grid-cols-6 gap-10 tracking-widest font-kg text-xl text-gray-400 text-center md:text-left ">
        <div></div>
            <div v-for="oferta in oferty" :key="oferta.id" class="">
                <div class=" -mb-36 -ml-10 font-kgout text-9xl text-orange">
                    {{oferta.id}}
                </div>
                <h1 class="my-10">{{oferta.dzial}}</h1>            
                <h1 class="mx-6 mb-5">{{oferta.opis}} To sztuka komunikacji pomiędzy sprzedającym a kupującym. Reklama dźwignią handlu, czyż nie? Dlatego warto zadbać, by ta dźwignia była skuteczna. Jak się wyróżnić na tle innych </h1>
                <div class="mx-5 my-10">
                    <div v-for="ofertap in oferta.zakres" :key="ofertap.podpunkt" class="flex">
                        <img class="h-4 w-auto m-1 " src="/img/kolko.png" alt="">
                        <div class="ml-3">
                            {{ofertap.podpunkt}}
                        </div>
                    </div>
                </div>
            </div>
        <div></div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Oferta2',
  props: {
    msg: String
  },
    data() {
        return {
            isOpen: false,
            oferty: [{
                    id: "1",
                    dzial: "PROJEKT LOGOTYPU",
                    opis: "Najważniejsza część identyfikacji wizualnej. Od niego zaczyna się cały proces budowania identyfikacji wizualnej.",
                    zakres: [{
                         podpunkt: "Proste"},
                        {podpunkt: "Unikalne "},
                        {podpunkt: "Ponadczasowe"},
                        {podpunkt: "Komunikujące"},
                        {podpunkt: "Zapamiętliwie"}
                    ]
                },
                {
                    id: "2",
                    dzial: "IDENTYFIKACJA WIZUALNA ",
                    opis: "W skład której wchodzą projektowanie:",
                    zakres: [{
                         podpunkt: "Papier firmowy"},
                        {podpunkt: "Wizytówki"},
                        {podpunkt: "Teczki"},
                        {podpunkt: "Foldery"},
                        {podpunkt: "Plakaty"},
                        {podpunkt: "Koszlki"},
                        {podpunkt: "Ulotki"},
                    ]
                },
                {
                    id: "3",
                    dzial: "REBRENDING",
                    opis: " kompletne odświeżenie identyfikacji wizualnej marki polegający na tym żeby przeobrazić wygląd produktu i wszystkiego co go reprezentuje.",
                    zakres: [                  
                    ]
                },
                {
                    id: "4",
                    dzial: "Projekty stron",
                    opis: "Każdy projekt strony internetowej staramy się robić spójny w stosunku do całej identyfikacji wizualnej marki. Za każdym razem tworzymy od zera",
                    zakres:  [
                    ]
                }
            ],
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>