<template>
  <div class="grid grid-cols-8 my-24" style=" background-size: cover;
            background-image: url('/img/tlo_home.png');
            ">
      <div></div>
      <div class="grid col-start-2 col-span-6 justify-items-center my-24">
        <div class=""
            >
            <img class="h-full w-full" src="/img/agencja_reklamowa.png" alt="">
        </div>
      </div>
      <div></div>
      <div class="grid col-start-3 col-span-4 mb ">
        <div class="font-kgsolid text-5xl text-orange">
         Reklamy, które niosą wartość.
        </div>
      </div>
      <div></div>
      <div></div>

      <div class="grid col-start-2 justify-items-start m-20 ">
        <img class="h-5 w-auto" src="/img/kwadrat_pelny.png" alt="">
      </div>
      <div class="grid justify-items-start  m-20">
        <img class="h-5 w-auto" src="/img/kwadrat_pusty.png" alt="">
      </div>
      <div class="grid justify-items-start  m-20">
        <img class="h-5 w-auto" src="/img/kwadrat_pusty.png" alt="">
      </div>
      <div class="grid justify-items-start  m-20">
        <img class="h-5 w-auto" src="/img/kwadrat_pusty.png" alt="">
      </div>
      <div class="grid justify-items-start  m-20">
        <img class="h-5 w-auto" src="/img/kwadrat_pusty.png" alt="">
      </div>
      <div class="grid justify-items-start  m-20">
        <img class="h-5 w-auto" src="/img/kwadrat_pusty.png" alt="">
      </div>
      <div class="grid justify-items-start">
        <img class="h-20 w-auto" src="/img/strzalka_dol.png" alt="">
      </div>
  </div>
</template>

<script>
export default {
  name: 'Homea',
  props: {
    msg: String
  }
}
</script>