<template>
<div class="grid grid-row-2 gap-5 mb-40">
    <div class="grid grid-cols-8 gap-4 my-10 tracking-widest">
        <div class=" col-start-3 col-span-5 mb-24 ">
        <div class=" my-5 font-kgout text-5xl md:text-7xl text-orange ">
        <p>STRONA INTERNETOWA</p>
        </div>
        <div class="font-kg text-2xl  text-gray-400">
            Obecność w sieci jest w dzisiejszych czasach nieodzownym elementem każdej firmy, która chce odnieść sukces na szerszą skalę. Począwszy od oryginalnie opracowanej strony wizytówkowej aż po aplikacje webowe na różne urządzenia dajemy wam możliwość nie tylko zaistnienie w tej przestrzeni ale i sprawnego prowadzenia biznesu.
        </div>
        </div>
    </div>
    <div class="md:grid grid-cols-6 gap-10 tracking-widest font-kg text-xl text-gray-400 text-center md:text-left ">
        <div></div>
            <div v-for="oferta in oferty" :key="oferta.id" class="">
                <div class=" -mb-36 -ml-10 font-kgout text-9xl text-orange">
                    {{oferta.id}}
                </div>
                <h1 class="my-10">{{oferta.dzial}}</h1>            
                <h1 class="mx-6 mb-5">{{oferta.opis}} To sztuka komunikacji pomiędzy sprzedającym a kupującym. Reklama dźwignią handlu, czyż nie? Dlatego warto zadbać, by ta dźwignia była skuteczna. Jak się wyróżnić na tle innych </h1>
                <div class="mx-5 my-10">
                    <div v-for="ofertap in oferta.zakres" :key="ofertap.podpunkt" class="flex">
                        <img class="h-4 w-auto m-1 " src="/img/kolko.png" alt="">
                        <div class="ml-3">
                            {{ofertap.podpunkt}}
                        </div>
                    </div>
                </div>
            </div>
        <div></div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Oferta3',
  props: {
    msg: String
  },
    data() {
        return {
            isOpen: false,
            oferty: [{
                    id: "1",
                    dzial: "Strony internetowe",
                    opis: "Każda strona - nawet najprostsza ma odzwierciedlać charakter firmy aby mogła ona być rozpoznawalna i zapamiętana wśród ogromnej ilości stron w sieci.",
                    zakres: [{
                         podpunkt: "Oryginalny projekt"},
                        {podpunkt: "Spójność brendingowa"},
                        {podpunkt: "Animacje"},
                        {podpunkt: "Łatwość w przeglądaniu"},
                        {podpunkt: "Pozycjonowanie strony"}
                    ]
                },
                {
                    id: "2",
                    dzial: "Strony mobilne.",
                    opis: "Nastały czasy kiedy do przeglądania internetu najczęściej stosuje się smartfony. Dlatego nasze realizacje zaczynamy od projektu, który na urządzeniach mobilnych będzie się jak najlepiej prezentował.",
                    zakres: [{
                         podpunkt: "Responsywność"},
                        {podpunkt: "Czytelność"},
                        {podpunkt: "Łatwa nawigacja"},
                        {podpunkt: "Przeglądanie offline"}                    ]
                },
                {
                    id: "3",
                    dzial: "Aplikacje webowe",
                    opis: "Tworzymy strony, które oprócz prezentacji firmy umożliwiają automatyzację wielu procesów biznesowych.",
                    zakres: [{
                         podpunkt: "Formularze kontaktowe"},
                        {podpunkt: "Tablice ogłoszeń"},
                        {podpunkt: "Ankiety"},
                        {podpunkt: "Integracja z Social Media"},
                        {podpunkt: "Obsługa konkursów"},
                        {podpunkt: "Karty rabatowe"},
                    ]
                },
                {
                    id: "4",
                    dzial: "Aplikacje mobilne",
                    opis: "Urządzenia mobilne wraz ze swoimi systemami operacyjnymi mają wiele praktycznych zastosowań dzięki działającym na nich aplikacjom. Można takie aplikacje z powodzeniem zastosować w każdym rodzaju działalności i tylko wyobraźnia ogranicza ich zakres.",
                    zakres:  [{
                         podpunkt: "Konsultacje zastosowań"},
                        {podpunkt: "Dedykowane aplikacje"},
                        {podpunkt: "Aplikacje Android"}
                    ]
                }
            ],
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>