<template>
<div>
  <div class="grid-rows-2">
    <div class="lg:grid grid-cols-8 gap-4 ">
      <div></div>
      <div class=" col-start-3 col-span-5 ">
        <div class="hidden md:block m-20 font-kgout text-7xl text-orange">
            OFERTA
        </div>
      </div>  
      <div class="place-content-center p-5 m-28 lg:m-4 grid col-start-2 col-span-2 px-20 border-4 border-gray-400 hover:border-gray-500 border-opacity-50">
          <img class="h-auto w-auto mt-10" src="/img/marketing.png" alt="">
          <div class="font-kg text-3xl text-gray-400 text-center m-5">
              <h1>MARKETING</h1>
          </div>
      </div>
      <div class="place-content-center p-5 m-28 lg:m-4 grid col-start-4 col-span-2 px-20 border-4 border-gray-400 hover:border-gray-500 border-opacity-50">
          <img class="h-auto w-auto mt-10" src="/img/branding.png" alt="">
          <div class="font-kg text-3xl text-gray-400 text-center m-5">
              <h1>BRANDING</h1>
          </div>
      </div>
      <div class="place-content-center p-5 m-28 lg :m-4 grid col-start-6 col-span-2 px-20 pt-10 border-4 border-gray-400 hover:border-gray-500 border-opacity-50">
          <img class="h-auto w-full mt-10" src="/img/strony.png" alt="">
          <div class="font-kg text-3xl text-gray-400 text-center mt-5">
              <h1>STRONY</h1>
          </div>        
      </div>    
      <div></div>
    </div>
    <div class="flex lg:grid grid-cols-8 gap-4 ">
      <div class="col-start-2 justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat_p.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Oferta',
  props: {
    msg: String
  }
}
</script>