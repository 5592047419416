<template>
<div class="grid grid-row-2 gap-5 mb-40">
    <div class="grid grid-cols-8 gap-4 my-10 tracking-widest">
        <div class=" col-start-3 col-span-5 mb-24 ">
        <div class=" my-5 font-kgout text-5xl md:text-7xl text-orange ">
        <p>MARKETING</p>
        </div>
        <div class="font-kg text-2xl  text-gray-400">
            To sztuka komunikacji pomiędzy sprzedającym a kupującym. Reklama dźwignią handlu, czyż nie? Dlatego warto zadbać, by ta dźwignia była skuteczna. Jak się wyróżnić na tle innych firma i sprzedawać więcej niż one? Jeśli potrzebujesz pomocy w tym zakresie, chętnie pomożemy Ci znaleźć odpowiedź na to pytanie.
        </div>
        </div>
    </div>
    <div class="md:grid grid-cols-6 gap-10 tracking-widest font-kg text-xl text-gray-400 text-center md:text-left ">
        <div></div>
            <div v-for="oferta in oferty" :key="oferta.id" class="">
                <div class=" -mb-36 -ml-10 font-kgout text-9xl text-orange">
                    {{oferta.id}}
                </div>
                <h1 class="m-10">{{oferta.dzial}}</h1>            
                <h1 class="mx-6 mb-5">{{oferta.opis}} To sztuka komunikacji pomiędzy sprzedającym a kupującym. Reklama dźwignią handlu, czyż nie? Dlatego warto zadbać, by ta dźwignia była skuteczna. Jak się wyróżnić na tle innych </h1>
                <div class="mx-5 my-10">
                    <div v-for="ofertap in oferta.zakres" :key="ofertap.podpunkt" class="flex">
                        <img class="h-4 w-auto m-1 " src="/img/kolko.png" alt="">
                        <div class="ml-3">
                            {{ofertap.podpunkt}}
                        </div>
                    </div>
                </div>
            </div>
        <div></div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Oferta1',
  props: {
    msg: String
  },
    data() {
        return {
            isOpen: false,
            oferty: [{
                    id: "1",
                    dzial: "Strategia marketingowa",
                    opis: "To sztuka komunikacji pomiędzy sprzedającym a kupującym. Reklama dźwignią handlu, czyż nie? Dlatego warto zadbać, by ta dźwignia była skuteczna. Jak się wyróżnić na tle innych",
                    zakres: [{
                         podpunkt: "Badanie rynku"},
                        {podpunkt: "Analiza"},
                        {podpunkt: "Określenie celów"},
                        {podpunkt: "Dobór narzędzi"},
                        {podpunkt: "Realizacja"}
                    ]
                },
                {
                    id: "2",
                    dzial: "MEDIA SPOŁECZNOŚCIOWE",
                    opis: "Rozwój mediów społecznościowych całkowicie zmienił oblicze biznesu. Obecnie media społecznościowe to najpotężniejszy kanał komunikacji bezpośredniej pomiędzy klientem a firmą. Zdaj się na naszą pomoc, a poprowadzimy twoje profile na:",
                    zakres: [{
                         podpunkt: "FACEBOOK"},
                        {podpunkt: "INSTAGRAM"},
                        {podpunkt: "YouTube"},
                        {podpunkt: "LinkedIn"},
                        {podpunkt: "TikTok"}
                    ]
                },
                {
                    id: "3",
                    dzial: "CONTENT MARKETING",
                    opis: "Książkę czytamy tydzień, film oglądamy 2 godziny, a czasami wystarczy spojrzeć na obraz i od razu wiemy, co autor chciał nam przekazać. Dlatego chcemy tworzyć dla naszych klientów treści, które w klarowny sposób zaprezentują ich mocne strony. Jeśli masz pomysł na film, grafikę, podcast, ale nie masz narzędzi, by je zrealizować lub potrzebujesz kreatywnego wsparcia w tej kwestii–stworzymy dla Ciebie koncepcję treści promocyjnych, a następnie ją zrealizujemy.",
                    zakres: [{
                         podpunkt: "Zdjęcia"},
                        {podpunkt: "Video"},
                        {podpunkt: "Podkasty"},
                        {podpunkt: "Prezentacje"},
                        {podpunkt: "Relacje nażywo"}
                    ]
                },
                {
                    id: "4",
                    dzial: "Marketing tradycyjny",
                    opis: "Pomimo tego, iż obecnie to Internet dominuje w kwestii promocji, nie należy bagatelizować starszych metod. Dobrze zaprojektowane materiały marketingowe, takie jak ulotki, plakaty czy gadżety firmowe świadczą o statusie firmy.",
                    zakres:  [{
                         podpunkt: "Kampanie offline"},
                        {podpunkt: "Materiały reklamowe"},
                        {podpunkt: "Bilbordy"},
                        {podpunkt: "Gadżety reklamowe"},
                        {podpunkt: "Eventy"}
                    ]
                }
            ],
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>