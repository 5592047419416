<template>
  <div class="home"
    style=" background-size: cover;
    background-image: url('/img/tlo.png');
    ">
    <Nav/>
    <Homea msg="Tojest test Fontów Eliz"/>
    <Onas/>
    <Oferta/>
    <Oferta1/>
    <Oferta2/>
    <Oferta3/>    
    <Wspolpraca/>
    <Klienci/>
    <Kontakt/>
  </div>
</template>

<script>
// @ is an alias to /src
import Homea from '@/components/Homea.vue'
import Nav from '@/components/Nav.vue'
import Onas from '../components/Onas.vue'
import Oferta from '../components/Oferta.vue'
import Oferta1 from '../components/Oferta1.vue'
import Oferta2 from '../components/Oferta2.vue'
import Oferta3 from '../components/Oferta3.vue'
import Wspolpraca from '../components/Wspolpraca.vue'
import Klienci from '../components/Klienci.vue'
import Kontakt from '../components/Kontakt.vue'

export default {
  name: 'Home',
  components: {
    Homea,
    Nav,
    Onas,
    Oferta,
    Wspolpraca,
    Oferta1,
    Oferta2,
    Oferta3,
    Klienci,
    Kontakt    
  }
}
</script>
