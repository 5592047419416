<template>
<div class="hidden md:block">
  <div class="grid grid-cols-8 ">
    <div></div>
      <div class=" col-start-3 col-span-2 ">
        <div class=" my-20 font-kgout text-7xl text-orange">
         O NAS
        </div>
          <div class="grid justify-items-start  mr-16 font-kg text-xl lg:text-3xl text-gray-400">
          <h1 class="">Doskonale wiemy, jak ważna jest odpowiednia komunikacja pomiędzy firmą a klientem, dlatego tworzymy treści, które w atrakcyjny sposób ukazują najmocniejsze strony reklamowanej firmy.Nasze usługi dedykujemy firmom, jak i markom osobistym.</h1>
          </div>        
        </div>
       <div class=" col-start-5 col-span-3 opacity-80 ">
        <div class=" my-20 font-kgout text-8xl lg:text-9xl text-orange ">
          <h1> NASZ </h1>  
          <h1> ZESPÓŁ </h1> 
        </div>
      </div>
      <div></div>
      <div class="grid justify-items-end col-start-4 m-4">
          <img class="h-20 w-auto" src="/img/stralka_pusta.png" alt="">
      </div>
      <div class="grid justify-items-start col-start-5 m-4">
          <img class="h-20 w-auto" src="/img/stralka_pelna.png" alt="">
      </div> 

      <div class="grid col-start-2 justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat_p.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
      <div class="grid justify-items-start m-20">
        <img class="h-5 w-auto" src="/img/kwadrat.png" alt="">
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Onas',
  props: {
    msg: String
  }
}
</script>